<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :table-title="'Категории'"
      :initial-sort-by="{field: 'sort', type: 'desc'}"
      deleteByIdUrl="/category/delete"
      deleteByIdMethod="post"
      :add-modal-input-data="addModalInputData"
      :update-modal-input-data="updateModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <template v-else-if="props.column.field === 'sort'">
            <input
              @keyup.enter="props.changeItemWithLoader(props.row['id'], {sort: $event.target.value})"
              type="number"
              :value="props.formattedRow[props.column.field]"
              :placeholder="props.formattedRow[props.column.field]"
            >
        </template>
        <div v-else @click="toggleShowModalUpdate(props)" style="cursor: pointer">
          <template v-if="props.column.field === 'img_path'">
            <img :src="props.formattedRow[props.column.field] + '?v=' + props.row.updated_at" :alt="props.formattedRow['title']" width="100" height="50">
          </template>
          <template v-else-if="props.column.field === 'type'">
            {{
              props.formattedRow[props.column.field]['description'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
            }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "categoriesList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      wrapperFiltersData: {
        title: "Категории",
        filtersUrl: "/category/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
      },
      columns: [
        {
          label: '',
          field: 'img_path',
          type: 'number',
          width: '15%',
          sortable: false,
        },
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Тип',
          field: 'type',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Название',
          field: 'title',
          width: '25%',
          sortable: true,
        },
        {
          label: 'Slug',
          field: 'slug',
          width: '25%',
          sortable: true,
        },
        {
          label: 'Сортировка',
          field: 'slug',
          sortable: true,
        },
        // {
        //   label: 'Удалить',
        //   field: 'delete',
        //   type: 'button',
        //   width: '20%',
        //   sortable: false,
        // },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          slug: elem['slug'],
          img_path: elem['img_path'],
          additional_param: elem['additional_param'],
          type: elem['type'],
          sort: elem['sort'],
          updated_at: elem['updated_at']
        });
      }
      return data;
    },
    toggleShowModalUpdate(props) {
      let elem = props.row
      if (elem) {
        this.selectedId = elem.id
        this.updateModalInputData.items.type.defaultValue = elem.type['id']
        this.updateModalInputData.items.title.defaultValue = elem.title
        this.updateModalInputData.items.slug.defaultValue = elem.slug
        this.updateModalInputData.items.h1.defaultValue = elem.additional_param ? elem.additional_param.h1 : null
        this.updateModalInputData.items.description.defaultValue = elem.additional_param ? elem.additional_param.description : null
        this.updateModalInputData.items.seo_keys.defaultValue = elem.additional_param ? elem.additional_param.seo_keys : null
        this.updateModalInputData.items.seo_title.defaultValue = elem.additional_param ? elem.additional_param.seo_title : null
        this.updateModalInputData.items.seo_description.defaultValue = elem.additional_param ? elem.additional_param.seo_description : null
      }

      props.toggleShowModalUpdate(props.row);
    },
  },
  computed: {
    updateModalInputData: function () {
      return {
        updateModalTitle: 'Категория ' + this.selectedId,
        updateModalUrl: '/category/update',
        updateModalMethod: 'post',
        updateModalPrimaryKey: 'id',
        items:{
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Название',
            defaultValue: '',
            selectedKey: 'title',
          },
          type: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/category/getTypeList',
            specifyKeyForLabel: 'description',
            upperSpanText: 'Тип категории',
            viewLabel: 'titleWithId',
            selectedKey: 'type',
          },
          slug: {
            simpleInputType: 'text',
            upperSpanText: 'slug',
            defaultValue: '',
            selectedKey: 'slug',
          },
          img: {
            upperSpanText: 'Картинка',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          h1: {
            simpleInputType: 'text',
            upperSpanText: 'h1',
            defaultValue: '',
            selectedKey: 'h1',
          },
          description: {
            simpleInputType: 'textarea',
            upperSpanText: 'Описание',
            defaultValue: '',
            selectedKey: 'description',
          },
          seo_keys: {
            simpleInputType: 'text',
            upperSpanText: 'SEO ключи',
            defaultValue: '',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            upperSpanText: 'SEO title',
            defaultValue: '',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            upperSpanText: 'SEO description',
            defaultValue: '',
            selectedKey: 'seo_description',
          },
        }
      }
    },
    addModalInputData: function () {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/category/create',
        addModalMethod: 'post',
        items:{
          title: {
            simpleInputType: 'text',
            placeholder: 'Название*',
            selectedKey: 'title',
          },
          type: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/category/getTypeList',
            specifyKeyForLabel: 'description',
            placeholder: 'Тип категории',
            viewLabel: 'titleWithId',
            selectedKey: 'type',
          },
          slug: {
            simpleInputType: 'text',
            placeholder: 'slug*',
            selectedKey: 'slug',
          },
          img: {
            upperSpanText: 'Картинка*',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          h1: {
            simpleInputType: 'text',
            placeholder: 'h1',
            selectedKey: 'h1',
          },
          description: {
            simpleInputType: 'textarea',
            placeholder: 'Описание',
            selectedKey: 'description',
          },
          seo_keys: {
            simpleInputType: 'text',
            placeholder: 'SEO keys',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            placeholder: 'SEO title',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            placeholder: 'SEO description',
            selectedKey: 'seo_description',
          },
        }
      }
    }
  }
}
</script>
